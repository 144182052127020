import React, {Component} from 'react';
import {graphql, Link, StaticQuery} from 'gatsby';

import styles from './careersHome.module.scss';
import image from '../../assets/images/conifers.jpg';

export class PureCareersHome extends Component {
  render() {
    const {data} = this.props;
    const {text} = data;
    const {frontmatter} = text;
    const {button_text, description, header} = frontmatter;
    return (
      <section
        className={styles.careerSection}
        aria-label='careers'
        style={{backgroundImage: `url(${image})`}}
      >
        <div className={'offset-md-2 col-md-4' + ' ' + styles.careerText}>
          <h2 className={styles.header}>{header}</h2>
          <p className={styles.paragraph}>{description}</p>
          <Link className={styles.button} to='/careers/' id="careers-link">{button_text}</Link>
        </div>
      </section>
    );
  }
}

export const CareersHome = props => (
  <StaticQuery
    query={graphql`
      query {
        text: markdownRemark(frontmatter: { path: { eq: "/home-careers/" } }) {
          frontmatter {
            header
            description
            button_text
          }
        }
      }
    `}
    render={data => <PureCareersHome {...props} data={data} />}
  />
);


export default CareersHome;