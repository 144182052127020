import React, {Component} from 'react';
import {graphql, Link, StaticQuery} from 'gatsby';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import styles from './whatWeDoHome.module.scss';

export class PureWhatWeDoHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      case_studies_hover: false,
      selected_capacity: 'app-development'
    };
  }

  updateSelected(value) {
    this.setState({selected_capacity: value});
  }

  renderCapacitiesList() {
    if (this.props.data.allMarkdownRemark.totalCount == 0) return null;

    const {selected_capacity} = this.state;

    const selected =  '#6C6969';
    const unselected = '#B0BBC5';

    const capacities_list = this.props.data.allMarkdownRemark.edges.map(({node}, index) => {
      const {frontmatter} = node;
      const {capacity_title, id} = frontmatter;

      return (
        <li
          className={styles.listItem}
          key={id}
          onMouseOver={() => this.updateSelected(id)}
          onFocus={() => this.updateSelected(id)}
        >
          <Link
            to={`/services/#${id}`}
            className={styles.listItemLink}
            style={{color: selected_capacity == id ? selected : unselected}}
            id={`list-${id}`}
            tabIndex={(index * 2) + 1}
          >
            {capacity_title}
          </Link>
        </li>
      );
    });
    return capacities_list;
  }

  renderTeaserContent() {
    const {case_studies_hover, selected_capacity} = this.state;

    const icon_array = this.props.data.icons.edges;

    const teaser_list = this.props.data.allMarkdownRemark.edges.map(({node}, index) => {
      if (node.frontmatter.id == selected_capacity) {
        const {frontmatter} = node;
        const {id, icon_alt, icon_name, home_link_text, home_link_url, home_teaser_content} = frontmatter;

        const getIcon = () => {
          const matching_icon = icon_array.find(icon => {
            if (icon.node.publicURL.includes(icon_name))
              return icon;
          });
          return matching_icon.node.publicURL;
        };

        return (
          <div key={id}>
            <img
              className={styles.customIcon}
              src={getIcon()}
              alt={icon_alt}
            />
            <p className={styles.paragraph} id={`teaser-${id}`}>{home_teaser_content}</p>
            <Link
              to={`/${home_link_url}`}
              state={{expanded: id}}
              className={styles.caseStudies}
              onMouseEnter={() => this.toggleCaseStudiesArrow()}
              onFocus={() => this.toggleCaseStudiesArrow()}
              onMouseLeave={() => this.toggleCaseStudiesArrow()}
              onBlur={() => this.toggleCaseStudiesArrow()}
              id={`teaser-link-${id}`}
              tabIndex={(index * 2) + 2}
            >
              {home_link_text}
              <FontAwesomeIcon
                icon={case_studies_hover ? ['far', 'long-arrow-right'] : ['fal', 'long-arrow-right']}
                className={'align-middle' + ' ' + styles.arrow}
              />
            </Link>
          </div>
        );
      }
    });

    return teaser_list;
  }

  toggleCaseStudiesArrow() {
    this.setState({case_studies_hover: !this.state.case_studies_hover});
  }

  render() {
    return (
      <section className={'row d-none d-md-flex' + ' ' + styles.whatWeDoHome} aria-label='what we do'>
        <div className={'offset-md-2 col-md-4'}>
          <ul className={styles.list}>
            {this.renderCapacitiesList()}
          </ul>
        </div>
        <div className={'offset-md-1 col-md-3 align-self-center' + ' ' + styles.teaser}>
          {this.renderTeaserContent()}
        </div>
      </section>
    );
  }
}

export const WhatWeDoHome = props => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark (
          filter: {
            fileAbsolutePath: { regex: "/capacities/" }
          },
          sort: {fields: frontmatter___order}
        ) {
          totalCount
          edges {
            node {
              id
              frontmatter {
                capacity_title
                id
                icon_alt
                icon_name
                home_link_text
                home_link_url
                home_teaser_content
              }
            }
          }
        }
        icons: allFile(
          filter: {relativePath: {regex: "/custom-icons\/capacities/"}},
          ) {
            edges {
              node {
                publicURL
              }
            }
        }
      }
    `}
    render={data => <PureWhatWeDoHome {...props} data={data} />}
  />
);


export default WhatWeDoHome;