import React, {Component} from 'react';
import {graphql, Link, StaticQuery} from 'gatsby';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import styles from './abouthome.module.scss';

export class PureAboutHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      more_hover: false
    };
  }

  toggleMoreArrow() {
    this.setState({more_hover: !this.state.more_hover});
  }


  render() {
    const {more_hover} = this.state;

    const {data} = this.props;
    const {contact, text} = data;
    const {frontmatter, html} = text;
    const {
      button_text,
      header,
      image_alt,
      more_link
    } = frontmatter;

    return (
      <section className={'row' + ' ' + styles.aboutSection} aria-label='about'>
        <div className={'offset-md-2 col-md-8'}>
          <h2 className={styles.header}>{header}</h2>
          <div className={styles.paragraph} dangerouslySetInnerHTML={{__html: html}} />
          <a
            className={styles.button}
            target="_blank"
            rel="noopener noreferrer"
            href={contact.frontmatter.info_href}
            id="cta-about-home"
          >
            {button_text}
          </a>
          <div>
            <Link
              to='/about/'
              className={styles.more}
              onMouseEnter={() => this.toggleMoreArrow()}
              onFocus={() => this.toggleMoreArrow()}
              onMouseLeave={() => this.toggleMoreArrow()}
              onBlur={() => this.toggleMoreArrow()}
              id="about-more"
            >
              {more_link}
              <FontAwesomeIcon
                icon={more_hover ? ['far', 'long-arrow-right'] : ['fal', 'long-arrow-right']}
                className={'align-middle' + ' ' + styles.arrow}
              />
            </Link>
          </div>
        </div>
      </section>
    );
  }
}

export const AboutHome = props => (
  <StaticQuery
    query={graphql`
      query {
        text: markdownRemark(frontmatter: { path: { eq: "/home-about/" } }) {
          frontmatter {
            button_text
            header
            image_alt
            more_link
          }
          html
        }
        contact: markdownRemark(frontmatter: { path: { eq: "/contact-info/" } }) {
          frontmatter {
            info_href
          }
        }
      }
    `}
    render={data => <PureAboutHome {...props} data={data} />}
  />
);

export default AboutHome;