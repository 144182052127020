import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import styles from './arrow.module.scss';

const Arrow = ({arrow_color, href, render_arrow, scrollToTopContent}) => {
  const arrow_class = render_arrow ? styles.showArrow : styles.hideArrow;

  return (
    <div className={'row' + ' ' + styles.row}>
      <button
        className={'d-none d-md-block' + ' ' + `${arrow_class}`}
        aria-label='scroll to content'
        onClick={scrollToTopContent}
        tabIndex={href == '#home-top-content' ? 1 : 0 }
        // // if users are tabbing through, their next tab will scroll them directly to the top-content
        // // rather than this potentially disctracting arrow
        // tabIndex={-1}
      >
        <FontAwesomeIcon icon={['fad', 'chevron-down']} className={styles.arrowStyle} style={{color: arrow_color}}/>
      </button>
    </div>
  );
};

export default Arrow;