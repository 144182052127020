const createObserver = (ratio, setCompState, threshold) => {
  let observer;

  observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.intersectionRatio <= ratio)
        setCompState('render_arrow', true);
      if (entry.intersectionRatio > ratio)
        setCompState('render_arrow', false);
    },
    {
      root: null,
      rootMargin: '0px',
      threshold: threshold
    }
  );

  return observer;
};

export default createObserver;