import React, {Component} from 'react';
import {graphql, Link, StaticQuery} from 'gatsby';
import {GatsbyImage} from 'gatsby-plugin-image';

import Arrow from '../shared/Arrow';
import styles from './bannerHome.module.scss';

export class PureBannerHome extends Component {

  render() {
    const {data, render_arrow, scrollToTopContent} = this.props;
    const {banner, image} = data;
    const {frontmatter} = banner;
    const {banner_image_alt, page_header, page_header_accent, tagline} = frontmatter;

    return (
      <section role='banner'>
        {/* large screen banner image*/}
        <GatsbyImage
          image={image.childImageSharp.gatsbyImageData}
          alt={banner_image_alt}
          className={'d-none d-md-block'}
        />
        {/* small screen banner image*/}
        <GatsbyImage
          image={image.childImageSharp.gatsbyImageData}
          alt={banner_image_alt}
          style={{height: '760px'}}
          objectFit="cover"
          objectPosition="30%"
          className={'d-block d-md-none'}
        />
        <div className={styles.bannerContent}>
          <div className={styles.title}>
            <h1 className={styles.header}>{page_header}<span className={styles.red}>{page_header_accent}</span></h1>
            <p className={styles.tagline}>
              <i>{tagline}</i>
            </p>
          </div>
          {/* small screen banner what we do buttons */}
          <ul className={'d-flex d-md-none' + ' ' + styles.buttonGroup} aria-label='what we do'>
            <li><Link className={styles.button} to='/services/#app-development' state={{expanded: 'app-development'}} id="button-app-development">App Development</Link></li>
            <li><Link className={styles.button} to='/services/#data-center-mgmt' state={{expanded: 'data-center-mgmt'}} id="button-data-center-mgmt">Data Center MGMT</Link></li>
            <li><Link className={styles.button} to='/services/#enterprise-apps' state={{expanded: 'enterprise-apps'}} id="button-enterprise-apps">Enterprise Applications</Link></li>
            <li><Link className={styles.button} to='/services/' id="button-services">More</Link></li>
          </ul>
          <Arrow
            arrow_color='#FFFFFF'
            href="#home-top-content"
            scrollToTopContent={scrollToTopContent}
            render_arrow={render_arrow}
          />
        </div>
      </section>
    );
  }
}

export const BannerHome = props => (
  <StaticQuery
    query={graphql`{
  banner: markdownRemark(frontmatter: {path: {eq: "/home-banner/"}}) {
    html
    frontmatter {
      banner_image_alt
      page_header
      page_header_accent
      tagline
    }
  }
  image: file(relativePath: {eq: "images/mountain-sunrise.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`}
    render={data => <PureBannerHome {...props} data={data} />}
  />
);

export default BannerHome;